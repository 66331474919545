import React, {ReactElement, useEffect, useState,} from "react"
import {Button, Grid, Typography} from "@material-ui/core"
import ImageCamera from "./ImageCamera"
import {v4} from "uuid"
import {getPresignedURL, saveThisPngDueName, sendToS3} from "../store/user/userActions"
import {blobToFile, dataURItoBlob} from "../lib/functions"
import {DueImage} from "../../interfaces"

interface DueCameraProps {
  unitId: number,
  dueImages: DueImage[] | undefined,
  setUuid?: React.Dispatch<React.SetStateAction<string>>,
  setRefresh: (leaveOpen: boolean) => void,
}

const DueImageTaker: React.FC<DueCameraProps> = ({unitId, dueImages, setUuid, setRefresh}): ReactElement => {

  const [takePicture, setTakePicture] = useState(false)
  const [imageData, setImageData] = useState<string>("")
  const [usePicture, setUsePicture] = useState(false)

  useEffect(() => {
    if (!imageData.length || !usePicture) return
    const fileName = `${v4()}.png`

    const blob = dataURItoBlob(imageData)
    const file: File = blobToFile(blob, fileName)
    getPresignedURL(0, unitId, fileName, "dueImage")
      .then(async function (signedUrl) {
        const options = {
          headers: {
            "Content-Type": file.type
          }
        }
        await sendToS3(signedUrl, file, options)
        const id = await saveThisPngDueName(unitId, fileName)
        if (!dueImages) {
          dueImages = []
        }
        dueImages.push({
          id: Number(id),
          unitId: unitId
        })
        setImageData("")
        setRefresh(true)
        if (setUuid) setUuid((current) => current + " ")
      })
  }, [unitId, imageData, usePicture, setUuid])

  if (takePicture) return (
    <>
      <ImageCamera setImageData={setImageData} imageData={imageData}/>
      <Button variant="outlined" onClick={() => {
        setTakePicture(false)
        setImageData("")
      }}>Cancel Picture</Button>
      {imageData.length ? <Button variant="outlined" onClick={() => {
        setUsePicture(true)
        setTakePicture(false)
      }}>Use Picture</Button>
        : <></>}
    </>
  )

  if (!imageData.length) {
    return !dueImages?.length ?
      <Grid item xs={12}>
        <Typography variant={"h2"}> No current images</Typography>
        <Button fullWidth size="small" variant="outlined" onClick={() => {
          setTakePicture(true)
        }}>Take Picture</Button>
      </Grid>
      :
      <Grid item xs={12}>
        <Button fullWidth size="small" variant="outlined" onClick={() => {
          console.log(unitId)
          setTakePicture(true)
        }}>Take Another Picture</Button>
      </Grid>
  } else {
    return <>Processing Image...</>
  }
}

export default DueImageTaker
