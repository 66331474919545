import {
  SET_LOGGED_IN,
  SET_LOGGED_OUT, SET_ORG_DATA,
  SET_TRY_LOGIN,
} from "../../lib/constants"
import {Organization, User} from "../../../interfaces"

export const emptyUser: User = {
  id: 0,
  fName: "",
  lName: "",
  image: "",
  email: "",
  roleId: 0,
}

export interface IUserState {
  user: User;
  loggedIn: boolean;
  tryLogin: boolean;
  orgData: Organization[];
}

export const initialUserState: IUserState = {
  user: emptyUser,
  loggedIn: false,
  tryLogin: false,
  orgData: [],
}

interface UserAction {
  type: string;
  payload: any; // NB this one is okay
}

export function userReducer(state: IUserState = initialUserState, action: UserAction): IUserState {
  switch (action.type) {
  case SET_LOGGED_IN: {
    return {
      ...state,
      user: action.payload.user,
      loggedIn: true,
      tryLogin: action.payload.tryLogin,
      orgData: [],
    }
  }
  case SET_LOGGED_OUT: {
    return {
      ...state,
      ...initialUserState,
      tryLogin: false,
      loggedIn: false,
      orgData: [],
    }
  }
  case SET_TRY_LOGIN: {
    return {
      ...state,
      tryLogin: action.payload
    }
  }
  case SET_ORG_DATA: {
    return {
      ...state,
      orgData: action.payload
    }
  } default: {
    return {
      ...state
    }
  }
  }
}
