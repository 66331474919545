import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
} from "react"
import {Camera, FACING_MODES} from "react-html5-camera-photo"
import "react-html5-camera-photo/build/css/index.css"

interface CameraProps {
  setImageData: Dispatch<SetStateAction<string>>,
  imageData: string,
}

const ImageCamera: React.FC<CameraProps> = ({setImageData, imageData}): ReactElement => {

  function handleTakePhotoAnimationDone (dataUri: string) {
    setImageData(dataUri)
  }

  const isFullscreen = false
  return (
    <div>
      {
        (imageData.length)
          ? <img src={imageData} alt="from camera" style={{maxWidth: "94%", marginLeft: "3%"}} />
          : <Camera isImageMirror={false} idealResolution = {{width: 2048, height: 2048}} idealFacingMode = {FACING_MODES.ENVIRONMENT} onTakePhotoAnimationDone = {(imageString) => { handleTakePhotoAnimationDone(imageString) }}                        isFullscreen={isFullscreen}
          />
      }
    </div>
  )
}

export default ImageCamera
