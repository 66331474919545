import axios from "axios"
import {ACCESS_TOKEN, AnyDispatchType, API_URL, REFRESH_TOKEN} from "../../lib/constants"
import {getISODate} from "../../lib/functions"
import {
  Manufacturer,
  Organization,
  Item,
  ItemType,
  Model,
  User,
  Property,
  Tag,
  Scan,
  Unit,
  TagBatch,
  Section,
  Vendor,
  Service,
  Services,
  EntrataProperty,
  ItemDetails,
  CustomBuilding, CSVOutput, DueReport, MaintenanceAnswer
} from "../../../interfaces"
import {finishLogin} from "../user/userActions"
interface configType {
  headers: {Authorization: string},
  params?: {
    searchTerm?: string
  }
}

const token = localStorage.getItem(ACCESS_TOKEN)
const config: configType = {
  headers: { Authorization: `Bearer ${token}` }
}

export const addNewService = async (itemId: number, scanId: number):Promise<number> => {
  const service: Service = { serviceDate: getISODate(), itemId, scanId }
  const response = await axios.post(`${API_URL}/serviceOpen`, service, config)
  return response.data.results.id
}

export const updateService = async (serviceId: number, notes: string, status?: number, vendorId?: number):Promise<Service[]> => {
  const service: Service = {}
  if (notes.length) {
    service.notes = notes
  }
  if (status) {
    service.status = status
  }
  if (vendorId !== undefined) {
    service.vendorId = vendorId
  }
  const response = await axios.put(`${API_URL}/serviceOpen/${serviceId}`, service, config)
  return response.data.results
}

export const getServiceData = async (itemId: number): Promise<Service[]> => {
  const response = await axios.put(`${API_URL}/serviceOpen/${itemId}`, config)
  return response.data.results
}

export const getUserServiceData = async (): Promise<Services> => {
  const response = await axios.get(`${API_URL}/service/`, config)
  return response.data.results
}

export const fetchManufacturers = async (): Promise<Manufacturer[]> => {
  const response = await axios.get(`${API_URL}/manufacturer/`, config)
  return response.data.results
}

export const fetchItemTypes = async (): Promise<ItemType[]> => {
  const response = await axios.get(`${API_URL}/item/types`, config)
  return response.data.results
}

export const fetchUnitWithDetails = async (unitId: number): Promise<Unit> => {
  const response = await axios.get(`${API_URL}/unit/${unitId}/details`, config)
  return response.data.results
}

export const addPropToOrg = async (prop: Property, propId: number, orgId: number):Promise<void> => {
  await axios.put(`${API_URL}/property/add/${propId}/org/${orgId}`, prop, config)
}

export const addNewOrganization = async (newOrganization: Organization):Promise<void> => {
  await axios.post(`${API_URL}/org`, newOrganization, config)
}

export const addNewProperty = async (newProperty: Property):Promise<number> => {
  const response = await axios.post(`${API_URL}/property`, newProperty, config)
  return response.data.results.id
}

export const addNewSection = async (newSection: Section):Promise<void> => {
  await axios.post(`${API_URL}/section`, newSection, config)
}

export const editASection = async (section: Section, sectionId: number):Promise<void> => {
  await axios.put(`${API_URL}/section/${sectionId}`, section, config)
}

export const editAProperty = async (property: Property, propertyId: number):Promise<void> => {
  await axios.put(`${API_URL}/property/${propertyId}`, property, config)
}

export const editAManufacturer = async (editedManufacturer: Manufacturer, manufacturerId: number):Promise<void> => {
  await axios.put(`${API_URL}/manufacturer/${manufacturerId}`, editedManufacturer, config)
}

export const editAnOrganization = async (editedOrganization: Organization, OrganizationId: number):Promise<void> => {
  await axios.put(`${API_URL}/org/${OrganizationId}`, editedOrganization, config)
}

export const addNewManufacturer = async (newManufacturer: Manufacturer):Promise<Manufacturer> => {
  const response = await axios.post(`${API_URL}/manufacturer`, newManufacturer, config)
  return response.data.results
}

export const addNewModel = async (newModel: Model):Promise<Model> => {
  const response = await axios.post(`${API_URL}/model`, newModel, config)
  return response.data.results
}

export const addNewItem = async (newItem: Item):Promise<void> => {
  await axios.post(`${API_URL}/item`, newItem, config)
}

export const setItemManufAndModel = async (newItem: Item):Promise<void> => {
  await axios.put(`${API_URL}/item/${newItem.id}/manufacturer`, newItem, config)
}

export const editAModel = async (editedModel: Model, modelId: number):Promise<void> => {
  await axios.put(`${API_URL}/model/${modelId}`, editedModel, config)
}

export const editAUnit = async (editedUnit: Unit, unitId: number):Promise<void> => {
  await axios.put(`${API_URL}/unit/${unitId}`, editedUnit, config)
}

export const getAdminOrganizations = async (searchTerm?: string):Promise<Organization[]> => {
  if (searchTerm) config.params = { searchTerm: searchTerm }
  const response = await axios.get(`${API_URL}/admin/organizations/withProperties`, config)
  config.params = { }
  return response.data.results
}

export const getAdminSimpleOrganizations = async ():Promise<Organization[]> => {
  const response = await axios.get(`${API_URL}/admin/organizations`, config)
  return response.data.results
}

export const getAdminTagBatches = async ():Promise<TagBatch[]> => {
  const response = await axios.get(`${API_URL}/tagBatch/all`, config)
  return response.data.results
}

export const getAdminUsers = async ():Promise<User[]> => {
  const response = await axios.get(`${API_URL}/admin/users`, config)
  return response.data.results
}

export const getProperty = async (id: number):Promise<Property> => {
  const response = await axios.get(`${API_URL}/property/${id}/unit`, config)
  return response.data.results
}

export const deleteThisOrganization = async (orgId: number):Promise<void> => {
  await axios.delete(`${API_URL}/org/${orgId}`, config)
}

export const deleteThisUnit = async (unitId: number):Promise<void> => {
  await axios.delete(`${API_URL}/unit/${unitId}`, config)
}

export const deleteThisSection = async (sectionId: number):Promise<void> => {
  await axios.delete(`${API_URL}/section/${sectionId}`, config)
}

export const deleteThisProperty = async (propertyId: number):Promise<void> => {
  await axios.delete(`${API_URL}/property/${propertyId}`, config)
}

export const fetchUsers = async (): Promise<Manufacturer[]> => {
  const response = await axios.get(`${API_URL}/user`, config)
  return response.data.results
}

export const doDeleteModel = async (modelIdToDelete: number) : Promise<void> => {
  await axios.delete(`${API_URL}/model/${modelIdToDelete}`, config)
}

export const doScan = async (uuid: string): Promise<{tag: Tag, scan: Scan}> => {
  const response = await axios.get(`${API_URL}/scanTag/${uuid}`, config)
  return response.data.results
}

export const addNewDueUnit = async (newUnit: Unit):Promise<void> => {
  await axios.post(`${API_URL}/unit/due`, newUnit, config)
}

export const getATagBatch = async (tagBatchId: number): Promise<TagBatch> => {
  const response = await axios.get(`${API_URL}/tagBatch/${tagBatchId}`, config)
  return response.data.results
}

export const createATagBatch = async (propertyId: number, sectionId: number, unitId: number): Promise<TagBatch> => {
  const response = await axios.post(`${API_URL}/tagBatch/`, {propertyId, sectionId, unitId}, config)
  return response.data.results
}

export const deleteThisItem = async (itemId: number):Promise<void> => {
  await axios.delete(`${API_URL}/item/${itemId}`, config)
}

export const getVendors = async ():Promise<Vendor[]> => {
  const response = await axios.get(`${API_URL}/vendor/`, config)
  return response.data.results
}

export const doOnboard = async (orgId: number, csvString: string): Promise<Property> => {
  const response = await axios.post(`${API_URL}/admin/onboard`, {orgId, csvString}, config)
  return response.data.results
}

export const saveUnitName = async (unitId: number, name: string): Promise<void> => {
  const unit: Unit = {id: unitId, name: name}
  await editAUnit(unit, unitId)
}

export const getAdminSimpleProperties = async ():Promise<Property[]> => {
  const response = await axios.get(`${API_URL}/admin/properties`, config)
  return response.data.results
}

export const adminUserUpdate = async (userId: number, userFName: string, userLName: string, userEmail: string, userRole: number): Promise<boolean> => {
  const response = await axios.put(`${API_URL}/admin/userUpdate`, {userId, userFName, userLName, userEmail, userRole}, config)
  return response.data.results.success
}

export const adminDeleteThisUser = async (userId: number): Promise<boolean> => {
  const response = await axios.delete(`${API_URL}/admin/${userId}`, config)
  return response.data.results.success
}

export const addUserToOrg = async (userId: number, orgId: number): Promise<boolean> => {
  const response = await axios.post(`${API_URL}/admin/userorg`, {userId, orgId }, config)
  return response.data.results.success
}

export const addUserToProp = async (userId: number, propId: number): Promise<boolean> => {
  const response = await axios.post(`${API_URL}/admin/userprop`, {userId, propId}, config)
  return response.data.results.success
}

export const getEntrataPropertyList = async (organizationId: number): Promise<EntrataProperty[]> => {
  const response = await axios.get(`${API_URL}/api/entrata/properties/`+ organizationId, config)
  return response.data.results
}

export const getPropertiesFromEntrata = async () => {
  await axios.get(`${API_URL}/api/entrata/properties/`, config)
}

export const importThisProperty = async (organizationId: number, propertyId: number) => {
  await axios.post(`${API_URL}/org/importfromentrata`, {orgId: organizationId, propId: propertyId} ,config)
}

export const addNewMaintenanceRecord = async (itemId: number, newRecord: string) => {
  await axios.post(`${API_URL}/record`, {itemId: itemId, recordTypeId: 1, information: newRecord} ,config)
}

export const hideRecord = async (recordId: number) => {
  await axios.put(`${API_URL}/record/${recordId}/hide`, {id: recordId} ,config)
}

export const showRecord = async (recordId: number) => {
  await axios.put(`${API_URL}/record/${recordId}/show`, {id: recordId} ,config)
}

export const hideMaint = async (maintId: number) => {
  await axios.put(`${API_URL}/maintenance/${maintId}/hide`, {id: maintId} ,config)
}

export const showMaint = async (maintId: number) => {
  await axios.put(`${API_URL}/maintenance/${maintId}/show`, {id: maintId} ,config)
}

export const hideMaintClose = async (maintId: number) => {
  await axios.put(`${API_URL}/maintenance/${maintId}/hideClose`, {id: maintId} ,config)
}

export const showMaintClose = async (maintId: number) => {
  await axios.put(`${API_URL}/maintenance/${maintId}/showClose`, {id: maintId} ,config)
}

export const hideNote = async (noteId: number) => {
  await axios.put(`${API_URL}/maintenance/${noteId}/hideNote`, {id: noteId} ,config)
}

export const showNote = async (noteId: number) => {
  await axios.put(`${API_URL}/maintenance/${noteId}/showNote`, {id: noteId} ,config)
}

export const getAllInvoices = async () => {
  const response = await axios.get(`${API_URL}/pdf`, config)
  return response.data.results.success
}

export const linkAndTransferThisPdf = async (unitId: number, itemId: number, pdfName: string) => {
  const response = await axios.put(`${API_URL}/pdf`, {unitId, itemId, pdfName} ,config)
  return response.data.results.success
}

export const setThisPdfAsParsed = async (propertyId: number, pdfName: string) => {
  const response = await axios.put(`${API_URL}/pdf`, {propertyId, pdfName} ,config)
  return response.data.results.success
}

export const adminBuildProperty = async (propertyId: number, buildings: CustomBuilding[]) => {
  const response = await axios.post(`${API_URL}/admin/buildproperty`, {propertyId, buildings} , config)
  return response.data.results.success
}

export const addItem = async (unitId: number, itemTypeId: number): Promise<ItemDetails[]> => {
  const response = await axios.put(`${API_URL}/unit/${unitId}/addItem/${itemTypeId}/`, {} ,config)
  return response.data.results
}

export const removeItem = async (unitId: number, itemTypeId: number): Promise<ItemDetails[]> => {
  const response = await axios.put(`${API_URL}/unit/${unitId}/removeItem/${itemTypeId}/`, {} ,config)
  return response.data.results
}

// export const uploadSerialPlateImage = async (itemId: number, data: string): Promise<void> => {
//   await axios.put(`${API_URL}/item/${itemId}/addSerialPlateImage/`, { data: data } ,config)
// }

export const saveThisPngName = async (unitId: number, itemId: number, name: string): Promise<string> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.put(`${API_URL}/itemUrl/${unitId}/${itemId}/addSerialImg`, {name: name}, config)
  return response.data.results.url
}

export const doLogin = async (refreshToken: string, dispatch: AnyDispatchType) => {
  const response = await axios.post(`${API_URL}/auth/login`, {refreshToken: refreshToken})
  if (response.status !== 200) {
    localStorage.removeItem(REFRESH_TOKEN)
  }
  await finishLogin(response.data.results, dispatch)
}

export const getInvoices = async (orgId: number, propertyId: number): Promise<CSVOutput[]> => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.get(`${API_URL}/api/entrata/invoices/${orgId}/${propertyId}`, config)
  return response.data.results
}

export const getDueReport = async (orgId: number, propertyId: number):Promise<DueReport> => {
  const response = await axios.get(`${API_URL}/due/${orgId}/${propertyId}`, config)
  return response.data.results
}

export const sendThisDueAnswer = async (answers: MaintenanceAnswer[]): Promise<void> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.post(`${API_URL}/due`, answers, config)
  return response.data.results.questions
}