import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react"
import {ReactElement} from "react-markdown"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from "@material-ui/core"
import {dashboardStyles} from "../../lib/theme"
import {addNewDueUnit, addNewSection} from "../../store/data/dataActions"
import {
  Property,
  Section, Unit
} from "../../../interfaces"
import {pullUserProperties} from "../../store/user/userActions"
import {useDispatch} from "react-redux"

interface UnitDialogueType {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  property: Property,
  section: Section,
}
export const UnitDialogue: React.FC<UnitDialogueType> = ({open, setOpen, property, section}): ReactElement => {

  const classes = dashboardStyles()
  const [newUnitName, setNewUnitName] = useState<string>("")
  const dispatch = useDispatch()

  const addThisSection = useCallback(async () => {
    const newUnit: Unit = {
      id: 0,
      name: newUnitName,
      propertyId: property?.id || 0,
      sectionId: section.id
    }
    await addNewDueUnit(newUnit)
    setNewUnitName("")
    setOpen(false)
    await pullUserProperties(localStorage.ACCESS_TOKEN, dispatch)
  }, [newUnitName])

  const sectionDialogue = useMemo(() => {
    return (<Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title"> Add Unit </DialogTitle>
      <DialogContent>
        <Grid container style={{marginBottom: 64}}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="name"
              label="Name"
              type="string"
              required
              inputProps={{maxLength: 100}}
              value={newUnitName}
              className={classes.fullWidthField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setNewUnitName(e.target.value)
              }}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.slice(0, 100)
              }}
            />
          </Grid>
          <Typography variant={"h3"}> The standard units for this organization will be added to this unit</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!newUnitName}
          onClick={() => {
            addThisSection()
          }}
        > Add </Button>
        <Button
          onClick={() => {
            setNewUnitName("")
            setOpen(false)
          }}
        > Cancel </Button>
      </DialogActions>
    </Dialog>)
  },[newUnitName, open])


  return ( <Grid item container xs={12}>
    <Grid item container style={{maxHeight: 200}}>
      {sectionDialogue}
    </Grid>
  </Grid>
  )
}