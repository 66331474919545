import { Action, createAction } from "redux-actions"
import axios from "axios"
import {
  IUserState,
} from "./userReducer"

import { Dispatch } from "react"
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  SET_LOGGED_IN,
  SET_LOGGED_OUT,
  SET_TRY_LOGIN,
  AnyDispatchType,
  SET_ORG_DATA, API_URL,
} from "../../lib/constants"
import {
  DueQuestion,
  DueReportAnswer, ItemDetails,
  Organization,
} from "../../../interfaces"

interface LoginUserOptions {
  email: string,
  loginPassword: string
}

const setLogin = createAction<IUserState>(SET_LOGGED_IN)
const setOrgData = createAction<Organization[]>(SET_ORG_DATA)
const setTryLogin = createAction<boolean>(SET_TRY_LOGIN)
const setLoggedOut = createAction<void>(SET_LOGGED_OUT)

function tryLogin(dispatch: Dispatch<Action<boolean>>, state: boolean): void {
  dispatch(setTryLogin(state))
}

export const refreshUser = async (dispatch?: AnyDispatchType): Promise<void> => {
  const refreshToken: string | null = localStorage.getItem(REFRESH_TOKEN)
  if (refreshToken?.length) {
    try {
      const response = await axios.post(`${API_URL}/auth/login`,{ refreshToken: refreshToken })
      if (response.status !== 200) {
        localStorage.removeItem(REFRESH_TOKEN)
      }
      await finishLogin(response.data.results, dispatch)

      if (dispatch) {
        return tryLogin(dispatch, false)
      }
    } catch (e) {
      console.log(e)
      if (dispatch) {
        return tryLogin(dispatch, false)
      }
      throw e
    }
  }
}

export const loginUser = async (loginDetails: LoginUserOptions, dispatch: AnyDispatchType): Promise<{reset: boolean, success: boolean}> => {
  const resetCheck = await axios.post(`${API_URL}/auth/login/check`,
    {email: loginDetails.email,
      password: loginDetails.loginPassword})

  if (resetCheck.data.results.success) {
    return {reset: true, success: false}
  }
  const response = await axios.post(`${API_URL}/auth/login`,
    {email: loginDetails.email,
      password: loginDetails.loginPassword})
  const finish = await finishLogin(response.data.results, dispatch)
  return {reset: false, success: finish}
}

export const sendToS3 = async (signedUrl: string, file: File, options: {}) => {
  try {
    return await axios.put(signedUrl, file, options)
  } catch (e) {
    console.log("sendToS3 Error", e)
  }
}

export const finishLogin = async (response: { jwt: string, refreshToken: string }, dispatch?: AnyDispatchType, ): Promise<boolean> => {
  if (response?.jwt?.length > 0) {
    const jwt = response.jwt
    const refreshToken = response.refreshToken
    localStorage.setItem(ACCESS_TOKEN, jwt)
    localStorage.setItem(REFRESH_TOKEN, refreshToken)

    if (dispatch) {
      const userInfo = parseJwt(jwt)
      const loginInfo: IUserState = {
        user: userInfo,
        loggedIn: true,
        tryLogin: false,
        orgData: []
      }
      await dispatch(setLogin(loginInfo))
      await pullUserProperties(jwt, dispatch)
    }
    return true
  } else {
    throw new Error("Login Failed, please try again")
  }
}

export const pullUserProperties = async (token: string, dispatch: AnyDispatchType): Promise<void> => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.get(`${API_URL}/user/properties`, config)
  const orgData: Organization[] = response.data.results
  await dispatch(setOrgData(orgData))
}

export const getPresignedURL = async (unitId: number, itemId: number, fileName: string, type = "pdf"): Promise<string> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  let response
  switch (type) {
  case "pdf":
    response = await axios.get(`${API_URL}/pdf/${itemId}/${fileName}`, config)
    break
  case "serialImages":
    response = await axios.get(`${API_URL}/itemUrl/${unitId}/${itemId}/getSerialURL/${fileName}`, config)
    break
  case "dueImage":
    response = await axios.get(`${API_URL}/due/${itemId}/getDueURL/${fileName}`, config)
    break
  default:
    response = {data: {results: {url: "Error"}}}
  }
  return response.data.results.url
}

export const saveThisPngDueName = async (unitId: number, name: string): Promise<string> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.put(`${API_URL}/due/${unitId}/addMaintenanceImg`, {name: name}, config)
  return response.data.results
}

export const logoutUser = async (dispatch: AnyDispatchType) => {
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
  await dispatch(setLoggedOut())
}

const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(""))
  return JSON.parse(jsonPayload)
}

export const getDueQuestions = async(propertyId: number): Promise<DueQuestion[]> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.get(`${API_URL}/due/duequestions/${propertyId}`, config)
  return response.data.results.questions
}

export const sendThisDueAnswer = async (answers: DueReportAnswer[]): Promise<void> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.post(`${API_URL}/due/`, answers, config)
  return response.data.results.questions
}

export const finishThisReport = async (reportId: number, emails: string[]): Promise<void> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  await axios.put(`${API_URL}/due/saveReport/${reportId}`, {reportId, emails}, config)
}

export const getSerialItemPics = async(unitId: number): Promise<ItemDetails[]> => {
  const token = localStorage.getItem(ACCESS_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  const response = await axios.get(`${API_URL}/item/serial/${unitId}`, config)
  return response.data.results
}

