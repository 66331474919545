import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react"
import {ReactElement} from "react-markdown"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core"
import {dashboardStyles} from "../../lib/theme"
import {addNewSection} from "../../store/data/dataActions"
import {
  Property,
  Section
} from "../../../interfaces"
import {pullUserProperties} from "../../store/user/userActions"
import {useDispatch} from "react-redux"

interface SectionDialogueType {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  property: Property,
}
export const SectionDialogue: React.FC<SectionDialogueType> = ({open, setOpen, property}): ReactElement => {

  const classes = dashboardStyles()
  const [newSectionName, setNewSectionName] = useState<string>("")
  const dispatch = useDispatch()

  const addThisSection = useCallback(async () => {
    const newSection: Section = {
      id: 0,
      name: newSectionName,
      propertyId: property?.id || 0,
    }
    await addNewSection(newSection)
    setNewSectionName("")
    setOpen(false)
    await pullUserProperties(localStorage.ACCESS_TOKEN, dispatch)
  }, [newSectionName])

  const sectionDialogue = useMemo(() => {
    return (<Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title"> Add Section </DialogTitle>
      <DialogContent>
        <Grid container style={{marginBottom: 64}}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="name"
              label="Name"
              type="string"
              required
              inputProps={{maxLength: 100}}
              value={newSectionName}
              className={classes.fullWidthField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setNewSectionName(e.target.value)
              }}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.slice(0, 100)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!newSectionName}
          onClick={() => {
            addThisSection()
          }}
        > Add </Button>
        <Button
          onClick={() => {
            setNewSectionName("")
            setOpen(false)
          }}
        > Cancel </Button>
      </DialogActions>
    </Dialog>)
  },[newSectionName, open])


  return ( <Grid item container xs={12}>
    <Grid item container style={{maxHeight: 200}}>
      {sectionDialogue}
    </Grid>
  </Grid>
  )
}