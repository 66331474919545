import React from "react"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import {parseISO, format, formatISO} from "date-fns"

export const addCommas = (nStr: string):string => {
  nStr += ""
  const x = nStr.split(".")
  let x1 = x[0]
  const x2 = x.length > 1 ? "." + x[1] : ""
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2")
  }
  return x1 + x2
}

export const makeMoney = (amountInPennies: number, currency: string): string => {
  // const symbol = currencySymbols.find(cs => cs.cc === currency)
  const value = addCommas((amountInPennies / 100).toFixed(2))
  // return symbol?.symbol + value
  return value
}

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
    Onsite Analytics
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "No Date"
  try {
    const theDate = parseISO(dateString)
    return format(theDate, "MMM d, yyyy")
  } catch (_) {
    return "No Date"
  }
}

export const getISODate = (): string => {
  return formatISO(new Date())
}

export const dataURItoBlob = (dataURI: string): Blob => {
  const binary = atob(dataURI.split(",")[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], {
    type: "image/png"
  })
}

export const blobToFile = (theBlob: Blob, fileName:string): File => {
  const b: any = theBlob
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date()
  b.name = fileName

  //Cast to a File() type
  return b
}
