import React, {ReactElement, useEffect, useState} from "react"
import { Button, Grid } from "@material-ui/core"
import ImageCamera from "./ImageCamera"
import { v4 } from "uuid"
import { getPresignedURL, sendToS3 } from "../store/user/userActions"
import { saveThisPngName } from "../store/data/dataActions"
import { blobToFile, dataURItoBlob } from "../lib/functions"
import { SerialImage } from "../../interfaces"

interface SerialPlateCameraProps {
  itemId: number,
  serialImages: SerialImage[],
  unitId: number,
  setUpdateImages: (unitId: number | undefined) => void,
  setUuid?: React.Dispatch<React.SetStateAction<string>>,
}

const SerialPlateImage: React.FC<SerialPlateCameraProps> = ({
  itemId,
  serialImages,
  setUuid,
  unitId,
  setUpdateImages,
}): ReactElement => {
  const [takePicture, setTakePicture] = useState(false)
  const [imageData, setImageData] = useState<string>("")
  const [usePicture, setUsePicture] = useState(false)

  useEffect(() => {
    if (!imageData.length || !usePicture) return
    const fileName = `${v4()}.png`

    const blob = dataURItoBlob(imageData)
    const file: File = blobToFile(blob, fileName)
    getPresignedURL(unitId, itemId, fileName, "serialImages").then(
      async function (signedUrl) {
        const options = {
          headers: {
            "Content-Type": file.type,
          },
        }
        await sendToS3(signedUrl, file, options)
        await saveThisPngName(unitId, itemId, fileName)
        setImageData("")
        setUpdateImages(unitId)
        if (setUuid) setUuid((current) => current + " ")
      }
    )
  }, [itemId, imageData, usePicture, setUuid])

  if (takePicture)
    return (
      <>
        <ImageCamera setImageData={setImageData} imageData={imageData} />
        <Button
          variant="outlined"
          onClick={() => {
            setTakePicture(false)
            setImageData("")
          }}
        >
					Cancel Picture
        </Button>
        {imageData.length ? (
          <Button
            variant="outlined"
            onClick={() => {
              setUsePicture(true)
              setTakePicture(false)
            }}
          >
						Use Picture
          </Button>
        ) : (
          <></>
        )}
      </>
    )
  else if (!imageData.length) {
    return !serialImages.length ? (
      <Grid item xs={12}>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => {
            setTakePicture(true)
          }}
        >
					Take Picture Of Serial Plate
        </Button>
      </Grid>
    ) : (
      <Grid item xs={12}>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => {
            setTakePicture(true)
          }}
        >
          Take Another Picture Of Serial Plate
        </Button>
      </Grid>
    )
  } else {
    return <>Processing Image...</>
  }
}

export default SerialPlateImage
