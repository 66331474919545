import React, {Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState} from "react"
import {ReactElement} from "react-markdown"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid, Radio, RadioGroup,
  Slider,
  TextField, Typography
} from "@material-ui/core"
import {dashboardStyles} from "../../lib/theme"
import {addNewSection} from "../../store/data/dataActions"
import {
  DueQuestion, DueReportAnswer,
  Property,
  Section, SingleDueOption, Unit
} from "../../../interfaces"
import {pullUserProperties, sendThisDueAnswer} from "../../store/user/userActions"
import {useDispatch} from "react-redux"
import DueImageTaker from "../DueImageTaker"

interface AnswerDialogueType {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  question: DueQuestion,
  dueQs: DueQuestion[],
  answers: DueReportAnswer[],
  userId: number | null,
  reportId: number,
  unit: Unit,
  update: boolean,
  setUpdate: Dispatch<SetStateAction<boolean>>,
  setFinishedUnits: Dispatch<SetStateAction<string[]>>,
  setRefresh: Dispatch<SetStateAction<boolean>>,
}
export const AnswerDialogue: React.FC<AnswerDialogueType> = ({
  open,
  setOpen,
  dueQs,
  answers,
  reportId,
  userId,
  unit,
  update,
  setUpdate,
  question,
  setFinishedUnits,
  setRefresh
}): ReactElement => {

  const classes = dashboardStyles()
  const [newSectionName, setNewSectionName] = useState<string>("")

  const addRadialAnswer = useCallback((questionId: number, value: boolean, text: string, optionId: number) => {
    let found = false
    if (answers.length && value) {
      answers.forEach((answer) => {
        if (answer.dueQuestionId === questionId && unit?.id && unit.id === answer.unitId) {
          answer.dueQuestionOptionId = optionId
          answer.userId = userId
          answer.dueReportId = reportId
          answer.textAnswer = text
          found = true
        }
      })
      if (!found && unit?.id) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          unitId: unit.id,
          textAnswer: text,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
      }
      setUpdate((current) => !current)
    } else {
      if (unit?.id && value) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          textAnswer: text,
          unitId: unit.id,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
      }
      setUpdate((current) => !current)
    }
  },[answers, userId, unit, reportId])

  const addSliderAnswer = useCallback((questionId: number, value: number | number[], optionId: number) => {
    let found = false
    if (answers.length && value) {
      answers.forEach((answer) => {
        if (answer.dueQuestionId === questionId  && unit?.id && unit.id === answer.unitId) {
          answer.dueQuestionOptionId = optionId
          answer.textAnswer = value.toString()
          answer.userId = userId
          answer.dueReportId = reportId
          found = true
        }
      })
      if (!found && unit?.id) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          textAnswer: value.toString(),
          unitId: unit.id,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
      }
      setUpdate((current) => !current)
    } else {
      if (unit?.id && value) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          textAnswer: value.toString(),
          unitId: unit.id,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
      }
      setUpdate((current) => !current)
    }
  },[answers, userId, unit, reportId])

  const addTextFieldAnswer = useCallback((questionId: number, value: string, optionId: number) => {
    let found = false
    if (answers.length && value) {
      answers.forEach((answer) => {
        if (answer.dueQuestionId === questionId && unit?.id && unit.id === answer.unitId) {
          answer.dueQuestionOptionId = optionId
          answer.textAnswer = value.toString()
          answer.userId = userId
          answer.dueReportId = reportId
          found = true
          setUpdate((current) => !current)
        }
      })
      if (!found && unit?.id) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          textAnswer: value.toString(),
          unitId: unit.id,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
        setUpdate((current) => !current)
      }
    } else {
      if (unit?.id && value) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          textAnswer: value.toString(),
          unitId: unit.id,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
        setUpdate((current) => !current)
      }
    }
  },[answers, userId, unit, reportId])

  const addCommentFieldAnswer = useCallback((questionId: number, value: string, optionId: number) => {
    let found = false
    if (answers.length) {
      answers.forEach((answer) => {
        if (answer.dueQuestionId === questionId && unit?.id && unit.id === answer.unitId) {
          answer.dueQuestionOptionId = optionId
          answer.textAnswer
          answer.comment = value.toString()
          answer.userId = userId
          answer.dueReportId = reportId
          found = true
          setUpdate((current) => !current)
        }
      })
      if (!found && unit?.id) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          textAnswer: "",
          comment: value.toString(),
          unitId: unit.id,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
        setUpdate((current) => !current)
      }
    } else {
      if (unit?.id) {
        answers.push({
          dueQuestionId: questionId,
          dueQuestionOptionId: optionId,
          textAnswer: "",
          comment: value.toString(),
          unitId: unit.id,
          userId: userId,
          dueReportId: reportId,
          dueImages: []
        })
        setUpdate((current) => !current)
      }
    }
  },[answers, userId, unit, reportId])

  const getSliderValue = useCallback((id: number, unitId: number): number | number[] => {
    let text = 1
    answers.forEach((answer) => {
      if (answer.dueQuestionId === id && unitId === answer.unitId) {
        text = Number(answer.textAnswer)
      }
    })
    return text
  },[answers])

  const getTextFieldValue = useCallback((id: number, unitId: number): string| undefined => {
    let text: string | undefined = ""
    answers.forEach((answer) => {
      if (answer.dueQuestionId === id && unitId === answer.unitId) {
        text = answer.textAnswer
      }
    })
    return text
  },[answers])

  const getCommentFieldValue = useCallback((id: number, unitId: number): string| undefined => {
    let text: string | undefined = ""
    answers.forEach((answer) => {
      if (answer.dueQuestionId === id && unitId === answer.unitId) {
        text = answer.comment
      }
    })
    return text
  },[answers])

  const getRadialValue = useCallback((id: number, unitId: number, optionId: number, questionAnswers: SingleDueOption[]): boolean => {
    let checked = false
    answers.forEach((answer) => {
      if(answer.dueQuestionId === id && unitId === answer.unitId) {
        questionAnswers.forEach((qAnswer) => {
          if (qAnswer.id === optionId && optionId === answer.dueQuestionOptionId) {
            checked = true
          }
        })
      }
    })
    return checked
  },[answers])

  const getDueImage = (questionId: number, unitId: number): ReactNode => {
    let image = <></>
    answers.forEach((answer) => {
      if (answer.dueQuestionId === questionId && answer.unitId === unitId) {
        image = <Grid item key={`camera - ${answer.id} - ${unitId} - ${answer.dueQuestionId}`} xs={12}><DueImageTaker unitId={unitId} dueImages={answer.dueImages} setRefresh={sendDueAnswer}/></Grid>
      }
    })
    return image
  }

  const getAnswerImages = useCallback((id: number, unitId: number): ReactNode[] => {
    const images: ReactNode[] = []
    answers.forEach((answer) => {
      if(answer.dueQuestionId === id && unitId === answer.unitId) {
        if (answer?.dueImages) {
          answer.dueImages.forEach((image) => {
            images.push(
              <Grid item xs={12} style={{height:"150px", width: "150px"}}>
                <img src={image?.link} alt='serial plate' style={{ width: "100%"}} />
              </Grid>
            )
          })
        }
      }
    })
    return images
  },[answers])

  const sendDueAnswer = (leaveOpen: boolean) => {
    const sendThisAnswer = async() => {
      await sendThisDueAnswer(answers)
      if (unit?.name) {
        setFinishedUnits((current) => {
          const newCurrent = [...current]
          newCurrent.push(unit?.name)
          return newCurrent
        })
      }
      setRefresh((current) => !current)
      setOpen(leaveOpen)
    }
    sendThisAnswer()
  }

  const getNameWithFinished = (questionId: number) => {
    let name = <></>
    let found = false
    answers.forEach((answer) => {
      if (answer.dueQuestionId === questionId && unit?.id && unit.id === answer.unitId) {
        found = true
      }
    })
    dueQs.forEach((question) => {
      if (question.question.id === questionId && !found) {
        name = <Grid item key={`no-answer-question${question.question.id}`} xs={12}><Typography variant={"h2"} >{question.question.question}</Typography> unanswered </Grid>
      } else if (question.question.id === questionId) {
        name = <Grid item key={`answer-question${question.question.id}`} xs={12}><Typography variant={"h2"} >{question.question.question}</Typography></Grid>
      }
    })
    return name
  }

  const thisQuestionAnswer = (): ReactNode => {
    const radials: ReactNode[] = []
    const answers: ReactNode[] = []
    const nodes: ReactNode[] = []
    question.answers.forEach((answer, index) => {
      try {
        const scaleCheck = answer.questionOption.split(",")
        if (scaleCheck.length > 1 && !isNaN(Number(scaleCheck[0]))) {
          nodes.push(
            <Grid xs={12} key={"slider-grid"} item>
              <Slider
                key={"slider"}
                valueLabelDisplay="auto"
                value={unit?.id ? getSliderValue(answer.dueQuestionId, unit.id) : undefined}
                defaultValue={Number(scaleCheck[0])}
                step={1}
                min={Number(scaleCheck[0])}
                max={Number(scaleCheck[1])}
                onChange={(e, value) => addSliderAnswer(answer.dueQuestionId, value, answer.id)}
              />
              {unit?.id ? getAnswerImages(answer.dueQuestionId, unit.id) : <></>}
              {unit?.id ? getDueImage(answer.dueQuestionId, unit.id) : <></>}
            </Grid>
          )
        } else {
          answers.push(
            <FormControlLabel
              key={`radialButton-${index}`}
              value={answer.questionOption} control={<Radio/>}
              checked={unit?.id ? getRadialValue(answer.dueQuestionId, unit.id, answer.id, question.answers) : false}
              label={answer.questionOption}
              onChange={(e, value) => addRadialAnswer(answer.dueQuestionId, value, answer.questionOption, answer.id)}/>)
        }
      } catch (e) {
        nodes.push(<TextField
          key={`commentBox-${index}`}
          multiline={true}
          variant="outlined"
          margin="normal"
          id="notes"
          label="Notes"
          type="string"
          inputProps={{maxLength: 250}}
          value={unit?.id ? getTextFieldValue(answer.dueQuestionId, unit.id) : ""}
          className={classes.fullWidthField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => {
            addTextFieldAnswer(answer.dueQuestionId, e.target.value, answer.id)
          }}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 250)
          }}
        />)
      }
      nodes.push(
        <TextField
          variant="outlined"
          margin="normal"
          id="comments"
          label="Comments"
          type="string"
          multiline={true}
          inputProps={{maxLength: 255}}
          value={unit?.id ? getCommentFieldValue(answer.dueQuestionId, unit.id) : ""}
          className={classes.fullWidthField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            addCommentFieldAnswer(answer.dueQuestionId, e.target.value, answer.id)
          }}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.slice(0, 255)
          }}
        />
      )
    })
    radials.push(
      <Grid container key={"outerContainer"} style={{marginBottom: 32}}>
        <Grid item xs={12}>{getNameWithFinished(question.question.id)}</Grid>
        <Grid container item xs={12}>
          {answers.length ?
            <RadioGroup name={question.question.question}>
              {answers}
            </RadioGroup> :
            nodes
          }
        </Grid>
      </Grid>)
    return <>{radials}</>
  }

  const sectionDialogue = useMemo(() => {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title"> Question </DialogTitle>
        <DialogContent>
          <Grid container style={{marginBottom: 64}}>
            <Grid item xs={12}>
              <Grid>
                {thisQuestionAnswer()}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false)
            }}
          > Close </Button>
        </DialogActions>
      </Dialog>)
  },[newSectionName, open, update, getAnswerImages, getDueImage])

  return ( <Grid item container xs={12}>
    <Grid item container style={{maxHeight: 200}}>
      {sectionDialogue}
    </Grid>
  </Grid>
  )
}