import React, {Dispatch, ReactNode, SetStateAction, useMemo, useState} from "react"
import Grid from "@material-ui/core/Grid"
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, Typography,
} from "@material-ui/core"
import {ReactElement} from "react-markdown"
import {
  DueQuestion,
  DueReportAnswer,
  Property,
} from "../../interfaces"
import {finishThisReport} from "../store/user/userActions"
import {dashboardStyles} from "../lib/theme"

interface DueQuestionnaireDialogueType {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  reportId: number,
  answers: DueReportAnswer[],
  setRefresh: Dispatch<SetStateAction<boolean>>,
  dueQs: DueQuestion[],
  property: Property | undefined,
  setProperty: Dispatch<SetStateAction<Property | undefined>>,
}
export const ReportFinishedDialogue: React.FC<DueQuestionnaireDialogueType> = ({open, setOpen, reportId, answers, setRefresh, dueQs, property, setProperty}): ReactElement => {
  const [email, setEmail] = useState<string>("")
  const [emails, setEmails] = useState<string[]>([])

  const classes = dashboardStyles()

  const finishReport = () => {
    const sendFinishReport = async () => {
      await finishThisReport(reportId, emails)
      setRefresh((current) => !current)
      setProperty(undefined)
      setEmail("")
      setEmails([])
      setOpen(false)
    }
    sendFinishReport()
  }

  const unfinishedUnits = useMemo(() => {
    const unitInfo: ReactNode[] = []
    property?.section ?
      property.section.forEach((section) => {
        section?.unit ?
          section.unit.forEach((unit) => {
            const answerCount = answers.filter((answer: DueReportAnswer) => answer.unitId === unit.id)
            if (answerCount.length < dueQs.length) {
              unitInfo.push(<Typography key={`section-unit-${section.id}-${unit.id}`} variant={"h3"}>{unit.name} is unfinished - {dueQs.length - answerCount.length} questions are still unanswered</Typography>)
            }
          }): <></>
      })
      : <></>
    property?.unit ?
      property.unit.forEach((unit) => {
        const answerCount = answers.filter((answer: DueReportAnswer) => answer.unitId === unit.id)
        if (answerCount.length < dueQs.length) {
          unitInfo.push(<Typography key={`unit-${unit.id}`} variant={"h3"}>{unit.name} is unfinished - {dueQs.length - answerCount.length} are still unanswered</Typography>)
        }
      })
      : unitInfo.push(<Typography key={`finished-${property?.id}`} variant={"h2"}>No Property Selected</Typography>)
    return unitInfo
  },[dueQs, answers, property])

  const emailList = useMemo(() => {
    const nodes: ReactNode[] = []
    emails.forEach((email) => {
      nodes.push(<Grid item xs={12}> <Typography variant={"h3"}>{email}</Typography></Grid>)
    })
    setEmail("")
    return nodes
  }, [emails])

  return <Grid item container xs={12}>
    <Grid item container style={{maxHeight: 200}}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => {
        }}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id="max-width-dialog-title">Finish Due Diligence Report</DialogTitle>
        <DialogContent>
          <Grid container style={{ marginBottom: 64 }} direction={"column"}>
            {unfinishedUnits}
            <Grid item xs={12}> <Typography variant={"h2"}>Emails</Typography></Grid>
            {emailList}
            <Grid item xs={12}>
              <TextField
                multiline={true}
                variant="outlined"
                margin="normal"
                id="email"
                label="Enter email(s) to send report to"
                type="string"
                inputProps={{maxLength: 250}}
                value={email}
                className={classes.fullWidthField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => {
                  setEmail(e.target.value)
                }}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.slice(0, 250)
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                    setEmails([...emails, email])
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={emails.length < 1} onClick={() => {
            finishReport()
          }}>finish</Button>
          <Button onClick={() => {
            setRefresh((current) => !current)
            setOpen(false)
          }}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  </Grid>

}
