import React, {ReactElement, useEffect, useMemo, useState} from "react"
import {dashboardStyles} from "../lib/theme"
import {Copyright} from "../lib/functions"
import DueHeader from "./DueHeader"
import {doLogin, getDueReport} from "../store/data/dataActions"
import {Button, CircularProgress, Grid, TextField, Typography,} from "@material-ui/core"
import {REFRESH_TOKEN} from "../lib/constants"
import {useDispatch, useSelector} from "react-redux"
import {IAppState} from "../store/store"
import {selectCurrentLoginState, selectProperties} from "../store/user/userSelectors"
import {DueQuestion, DueReportAnswer, Property, Section, Unit} from "../../interfaces"
import {Autocomplete} from "@material-ui/lab"
import IncompleteIcon from "@material-ui/icons/RadioButtonUnchecked"
import CompleteIcon from "@material-ui/icons/RadioButtonChecked"
import {DueQuestionnaireDialogue} from "./DueQuestionnaireDialogue"
import {ReportFinishedDialogue} from "./ReportFinishedDialogue"
import {getDueQuestions} from "../store/user/userActions"
import {SectionDialogue} from "./dialogues/SectionDialogue"
import {UnitDialogue} from "./dialogues/UnitDialogue"

const DueDilligence: React.FC = (): ReactElement => {
  const classes = dashboardStyles()
  const dispatch = useDispatch()

  const loggedIn: boolean = useSelector((state: IAppState) => selectCurrentLoginState(state))
  const properties: Property[] = useSelector((state: IAppState) => selectProperties(state))

  const [tryLogin, setTryLogin] = useState(true)
  const [property, setProperty] = useState<Property>()
  const [unit, setUnit] = useState<Unit>()
  const [finishedUnits, setFinishedUnits] = useState<string[]>([])
  const [dueReportId, setDueReportId] = useState<number>()
  const [dueReportAnswers, setDueReportAnswers] = useState<DueReportAnswer[]>([])
  const [refresh, setRefresh] = useState(false)  // flip this after you save a new dueAnswer set.
  const [open, setOpen] = useState<boolean>(false)
  const [reportFinished, setReportFinished] = useState<boolean>(false)
  const [dueQs, setDueQs] = useState<DueQuestion[]>([])
  const [addSection, setAddSection] = useState<boolean>(false)
  const [section, setSection] = useState<Section | undefined>(undefined)
  const [addUnit, setAddUnit] = useState<boolean>(false)

  useEffect(() => {
    const doGetDueReportId = async (): Promise<void> => {
      if (property?.id && property?.organizationId) {
        const report = await getDueReport(property.organizationId, property.id)
        setDueReportId(report.id)
        setDueReportAnswers(report.answers)
        const rawUnits: (string)[] = []
        report.answers.forEach(answer => {
          let unitName: string | undefined = undefined
          property.section?.forEach(section => {
            section.unit?.forEach(unit => {
              if (unit.id === answer.unitId) unitName = unit.name
            }
            )
          })
          if (!!unitName && !rawUnits.includes(unitName)) {
            rawUnits.push(unitName)
          }
        })

        setFinishedUnits(rawUnits)
      }
    }

    doGetDueReportId()
  }, [property, refresh])

  useEffect(() => {
    const getQuestions = async() => {
      if (property?.id) {
        setDueQs(await getDueQuestions(property.id))
      }
    }
    getQuestions()
  },[property?.id])

  useEffect(() => {
    if (loggedIn) setTryLogin(false)
  }, [loggedIn])

  useEffect(() => {
    const refreshToken: string | null = localStorage.getItem(REFRESH_TOKEN)
    if (refreshToken?.length && !loggedIn) {
      doLogin(refreshToken, dispatch)
    }
  }, [dispatch, loggedIn])

  useEffect(() => {
    if (property) {
      const newProp = properties.find((prop) => prop.id === property.id)
      if (newProp) {
        setProperty(newProp)
      }
    }
  }, [properties])

  const sectionList = useMemo(() => {
    return (
      property?.section?.sort((a, b) => a.name > b.name ? 1 : -1).map((section, outerKey) => {
        return <Grid key={outerKey} item xs={12} spacing={2} container style={{
          border: "1px solid black",
          borderRadius: 5,
          textAlign: "center",
          marginBottom: 16,
          marginTop: 16
        }}>
          <Grid item xs={12}>
            <Typography variant="h3">{section.name}</Typography>
          </Grid>
          {section.unit?.sort((a, b) => a.name > b.name ? 1 : -1).map((unit, innerKey) => {
            return <Grid key={innerKey} item xs={4} sm={3} md={2} lg={1}>
              <Button fullWidth startIcon={finishedUnits.includes(unit.name) ? <CompleteIcon/> : <IncompleteIcon/>}
                onClick={() => {
                  setUnit(unit)
                  setOpen(true)
                }} variant="contained">{unit.name}</Button>
            </Grid>})
          }
          <Button variant={"contained"} style={{margin: "2px"}} onClick={() => {setSection(section); setAddUnit(true)}}> Add Unit </Button>
        </Grid>
      }) || <></>

    )
  }, [refresh, properties, property])

  return <>
    <DueHeader propertyName={property?.name || ""} unitName={unit?.name || ""} type="unit" options={property?.unit}
      setTryLogin={setTryLogin} tryLogin={tryLogin}/>
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        {properties.length ?
          <Autocomplete
            // value={property}
            options={properties}
            getOptionLabel={(option: Property) => option.name}
            onChange={(event, value: Property | null) => {
              if (value) {
                setProperty(value)
              }
            }}
            id="property-combo"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Properties"/>}/>
          : <Typography variant="h2">Loading... <CircularProgress/></Typography>}
      </Grid>
      <Grid item xs={12} container>
        {sectionList}
        <Button variant={"contained"} style={{margin: "2px"}} onClick={() => setAddSection(true)}> Add building </Button>
        <Button variant={"contained"} disabled={!property || !dueReportId} fullWidth onClick={()=>setReportFinished(true)}> Finish Report</Button>
      </Grid>
      {dueReportId ? <DueQuestionnaireDialogue open={open} setOpen={setOpen} unit={unit} reportId={dueReportId} answers={dueReportAnswers} setFinishedUnits={setFinishedUnits} setRefresh={setRefresh} dueQs={dueQs} /> : <></>}
      {dueReportId ? <ReportFinishedDialogue open={reportFinished} setOpen={setReportFinished}  reportId={dueReportId} answers={dueReportAnswers} setRefresh={setRefresh} dueQs={dueQs} property={property} setProperty={setProperty}/> : <></>}
      {dueReportId && property?.id ? <SectionDialogue open={addSection} setOpen={setAddSection} property={property}/> : <></>}
      {dueReportId && property?.id && section ? <UnitDialogue open={addUnit} setOpen={setAddUnit} property={property} section={section}/> : <></>}
    </Grid>
    <Copyright/>
  </>
}

export default DueDilligence
