import React from "react"
import ReactDOM from "react-dom"
import { light, dark } from "./theme"
import { ThemeProvider } from "@material-ui/core/styles"
import "./index.css"
import {Container, CssBaseline} from "@material-ui/core"
import {Provider} from "react-redux"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import configureStore from "./store/store"
import DueDilligence from "./components/DueDilligence"


const store = configureStore()
const darkStateStorage = localStorage.getItem("darkState") || "light"

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={ darkStateStorage === "dark" ? dark : light }>
      <CssBaseline />
      <Router>
        <div className="App">
          <Container maxWidth="xl">
            <Switch>
              <Route path="/" exact component={DueDilligence} />
            </Switch>
          </Container>
        </div>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.querySelector("#root"),
)
