import { IAppState } from "store/store"
import {Property, User} from "../../../interfaces"
import {emptyUser} from "./userReducer"

//This is so the rest of the program doesn't break. Will refactor later
export const selectCurrentUser = (state: IAppState): User =>
  (state.userState?.user) ? state.userState?.user : emptyUser

export const selectCurrentUserFirstName = (state: IAppState): string | null =>
  (state.userState?.user?.fName) ? state.userState?.user?.fName : null

export const selectCurrentUserLastName = (state: IAppState): string | null =>
  (state.userState?.user?.lName) ? state.userState?.user?.lName : null

export const selectCurrentLoginState = (state: IAppState): boolean =>
  state.userState?.loggedIn

export const selectTryLogin = (state: IAppState): boolean =>
  state.userState?.tryLogin

export const selectCurrentState = (state: IAppState): IAppState => state

export const selectCurrentUserId = (state: IAppState): number | null =>
  (state.userState?.user?.id) ? state.userState?.user?.id : null

export const selectProperties = (state: IAppState): Property[] => {
  const properties: Property[] = []
  state.userState.orgData.forEach(org => {
    org.properties?.forEach((property: Property) => {
      property.organizationId = org.id
      properties.push(property)
    })
  })
  return properties
}
