/* eslint max-lines: "off" */
import { MenuItem } from "@material-ui/core"
import React, { Dispatch, ReactElement } from "react"

export const PROD_API_URL = "https://api.onsite-analytics.com"
export const DEV_API_URL = "https://dev-api.onsite-analytics.com"
export const LOCAL_API_URL = "http://localhost:4000/development"
let url = ""
switch(process?.env?.NODE_ENV) {
case "production":
  url = PROD_API_URL
  break
case "test":
  url = DEV_API_URL
  break
case "development":
  url = LOCAL_API_URL
  break
case "development":
default:
  url = LOCAL_API_URL
}

export const API_URL = url

export const ACCESS_TOKEN = "ACCESS_TOKEN"
export const REFRESH_TOKEN = "REFRESH_TOKEN"

//userActions
export const SET_TRY_LOGIN = "SET_TRY_LOGIN"
export const SET_LOGGED_IN = "SET_LOGGED_IN"
export const SET_ORG_DATA = "SET_ORG_DATA"
export const SET_LOGGED_OUT = "SET_LOGGED_OUT"
export const SET_CATEGORIES = "SET_CATEGORIES"

// @ts-ignore
export type AnyDispatchType = Dispatch<any>

// @ts-ignore
export type AnyChangeEventType = React.ChangeEvent<any>

// @ts-ignore
export type OnClickType = MouseEvent<HTMLLIElement> | MouseEvent<HTMLAnchorElement>

// "?<!" is not supported in Safari
// export const regexpNumber = /(^(?<!.)\d+((\.\d{0,2})|)$)|(^$)/
export const regexpNumber = /(^\d+((\.\d{0,2})|)$)|(^$)/
export const regexpOneSymbolOrNumber = /[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]|.*[0-9].*/
export const regExpOneLetter = /[[a-zA-Z]/

export const emptyRoute = {
  isDivider: false,
  isLogo: false,
  isSub: false,
  key: "",
  label: "",
  route: "",
  value: ""
}

export const passwordStrengthOptions: any = [
  { id: 0, value: "Too weak", minDiversity: 0, minLength: 0 },
  { id: 1, value: "Weak", minDiversity: 2, minLength: 6 },
  { id: 2, value: "Medium", minDiversity: 3, minLength: 8 },
  { id: 3, value: "Strong", minDiversity: 4, minLength: 10 }
]

export const bugIssueTypes: { name: string }[] = [
  { name: "Something Not Working" },
  { name: "Billing Issue" },
  { name: "Missing Feature" },
  { name: "Other" },
]


export interface FormFields {
  [key: string]: {label: string, required: boolean, phone: boolean, email: boolean}
}

export const phoneFields = {
  cellPhone: true,
  homePhone: true,
  phone: true
}

export const emailFields = {
  email: true
}

export interface AsFields {
// @ts-ignore
  [key: string]: any
}

export const judgePersonFields: FormFields = {
  legalFirstName: { label: "First Name", required: true, phone: false, email: false },
  legalMiddleName: { label: "Middle Name", required: false, phone: false, email: false },
  legalLastName: { label: "Last Name", required: true, phone: false, email: false },
  suffix: { label: "Suffix (Jr, Sr, etc.)", required: false, phone: false, email: false },
  nickName: { label: "Nick Name", required: false, phone: false, email: false },
  title: { label: "Title", required: false, phone: false, email: false },
  cellPhone: { label: "Cell Phone", required: true, phone: true, email: false },
  homePhone: { label: "Home Phone", required: false, phone: true, email: false },
  email: { label: "Email", required: true, phone: false, email: true },
  birthDate: { label: "Birth Date", required: false, phone: false, email: false },
  gender: { label: "Gender", required: false, phone: false, email: false },
  address1: { label: "Address", required: false, phone: false, email: false },
  address2: { label: "Address 2", required: false, phone: false, email: false },
  city: { label: "City", required: true, phone: false, email: false },
  state: { label: "State or Provence", required: true, phone: false, email: false },
  postalCode: { label: "Postal Code", required: true, phone: false, email: false },
  country: { label: "Country", required: true, phone: false, email: false },
}

export interface UsStates {
  name: string;
  abbreviation: string;
}

export const usStates: UsStates[] = [
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
]

export const stateItems: ReactElement[] = []
for (const state of usStates) {
  stateItems.push(<MenuItem key={ "USStates-" + state.abbreviation} value={state.abbreviation}>{state.name}</MenuItem>)
}

export const USER_ROLES = [{name: "User", id: 0}, {name: "Service Tech", id: 1}, {name: "Property Manager", id: 2}, {name: "Owner", id: 3}]
