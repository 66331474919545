import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react"
import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from "@material-ui/core"
import {dashboardStyles} from "lib/theme"
import {useDispatch, useSelector} from "react-redux"
import {IAppState} from "../store/store"
import {
  selectCurrentLoginState,
  selectCurrentUserFirstName,
  selectCurrentUserLastName
} from "../store/user/userSelectors"
import {loginStyle} from "../theme"
import {loginUser } from "../store/user/userActions"
import {Unit} from "../../interfaces"
import {Autocomplete} from "@material-ui/lab"

interface TagHeaderType {
  propertyName: string,
  unitName: string,
  type: string,
  options?: Unit[],
  tryLogin?: boolean,
  setTryLogin?: React.Dispatch<React.SetStateAction<boolean>>,
}

const DueHeader: React.FC<TagHeaderType> = ({propertyName, unitName, options, tryLogin, setTryLogin}): ReactElement => {
  const classes = dashboardStyles()
  const classesLogin = loginStyle()
  const dispatch = useDispatch()

  const images = require.context("../../public/images", true)
  const logo = images("./OA Logo.png").default

  const loggedIn: boolean = useSelector((state: IAppState) => selectCurrentLoginState(state))
  const firstName: string | null = useSelector((state: IAppState) => selectCurrentUserFirstName(state))
  const lastName: string | null = useSelector((state: IAppState) => selectCurrentUserLastName(state))
  const userName = "" + firstName + " " + lastName

  const [doLogin, setDoLogin] = useState(false)
  const [loginPassword ,setLoginPassword] = useState("")
  const [email ,setEmail] = useState("")
  const [current, setCurrent] = useState<Unit>()

  const submitForm: any = useCallback(async (event: any) => {
    setDoLogin(true)
    event.preventDefault()
    if (setTryLogin===undefined) return
    try {
      const loginCheck = await loginUser({ email, loginPassword }, dispatch)
      setDoLogin(false)
      if (loginCheck.success) {
        setTryLogin(false)
      }
    } catch (e) {
      setTryLogin(true)
      setDoLogin(false)
      console.error(e)
    }
  },[dispatch, email, loginPassword, setTryLogin])

  const loginDialog = useMemo(() => {
    return (
      <Dialog
        fullWidth
        maxWidth='xs'
        open={tryLogin || false}
        onClose={() => { if (setTryLogin!==undefined) setTryLogin(false) }}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id="max-width-dialog-title">Login</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{marginBottom: 64}}>
            <Grid item xs={12} style={{textAlign: "center"}}>
              <form className={classesLogin.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={e => setEmail(e.target.value)}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="loginPassword"
                  label="Password"
                  type="password"
                  id="loginPassword"
                  autoComplete="current-password"
                  onChange={e => setLoginPassword(e.target.value)}
                />
                {/*<FormControlLabel*/}
                {/*    control={<Checkbox value="remember" color="primary" />}*/}
                {/*    label="Remember me"*/}
                {/*/>*/}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classesLogin.submit}
                  onClick={submitForm}
                >
                  { doLogin ? <span>Logging In <CircularProgress color="secondary" /></span> : "Sign In" }
                </Button>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

    )
  }, [tryLogin, doLogin, classesLogin, setLoginPassword, setEmail, setTryLogin, submitForm])

  useEffect(() => {
    options?.sort((a, b) => a.name > b.name ? 1 : -1 )
  }, [options])

  useEffect(() => {
    setCurrent(options?.find(option => {
      return option.name === unitName
    }))
  }, [options, unitName])

  return <Grid container>
    <Grid item xs={3} xl={1}>
      <img key="EALogo" src={logo} className={classes.headerLogo} alt="Onsite Analytics Logo"/>
    </Grid>
    <Grid item xs={7} xl={10}>
      <Typography variant="h3" style={{marginTop: 20, textAlign: "center"}}>
        {propertyName}
      </Typography>
      { options?.length && loggedIn ?
        <Autocomplete
          disablePortal
          id="combo-box"
          getOptionLabel={(option) => {
            return option.name || ""
          }}
          value={current}
          options={options}
          onChange={(event, value: Unit | undefined | null) => {
            if (!value) {
              value = undefined
            }
            setCurrent(value)
          }}
          renderInput={(params) => <TextField {...params} label="Unit #"/>}
        /> : <></> }
    </Grid>
    <Grid item xs={2} xl={1} style={{textAlign: "right", fontSize: 12, marginTop: 12}}>
      { !loggedIn ? <Button size="small" onClick={() => { if (setTryLogin!==undefined) setTryLogin(true) }}>Log In</Button>
        : <Box>{userName}</Box>
      }
    </Grid>
    { loginDialog }
  </Grid>
}

export default DueHeader

