import React, {Dispatch, ReactNode, SetStateAction, useMemo} from "react"
import Grid from "@material-ui/core/Grid"
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogTitle, Typography,
} from "@material-ui/core"
import {ReactElement} from "react-markdown"
import {ItemDetails} from "../../interfaces"
import SerialPlateImage from "./SerialPlateImage"

interface SerialPlateImagesDialogueType {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  items: ItemDetails[],
  setItems: Dispatch<SetStateAction<ItemDetails[]>>,
  unitId: number,
  setUpdateImages: (unitId: number | undefined) => void,
}
export const SerialPlateImagesDialogue: React.FC<SerialPlateImagesDialogueType> = ({open, setOpen, items, setItems, unitId, setUpdateImages}): ReactElement => {

  const itemSerials = useMemo(() => {
    const serialInfo: ReactNode[] = []
    items.forEach((item: ItemDetails) => {
      serialInfo.push(<Typography key={`item-${item.id}`} variant={"h3"}>{item.name}: {item.serialImages ? <SerialPlateImage itemId={item.id} serialImages={item.serialImages} unitId={unitId} setUpdateImages={setUpdateImages}/>: <div></div> }</Typography>)
    })
    return serialInfo
  },[items])

  return <Grid item container xs={12}>
    <Grid item container style={{maxHeight: 200}}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => {
        }}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id="max-width-dialog-title">Take or Update Serial Plate Pictures</DialogTitle>
        <DialogContent>
          <Grid container style={{ marginBottom: 64 }} direction={"column"}>
            {itemSerials}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
            setItems([])
          }}>Done</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  </Grid>

}
