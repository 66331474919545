import React, {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState} from "react"
import Grid from "@material-ui/core/Grid"
import {dashboardStyles} from "../lib/theme"
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup, Slider, TextField, Typography, Icon
} from "@material-ui/core"
import {ReactElement} from "react-markdown"
import {
  DueQuestion,
  DueReportAnswer, ItemDetails, SingleDueOption,
  Unit
} from "../../interfaces"
import {getSerialItemPics, sendThisDueAnswer} from "../store/user/userActions"
import {useSelector} from "react-redux"
import {IAppState} from "../store/store"
import {selectCurrentUserId} from "../store/user/userSelectors"
import DueImageTaker from "./DueImageTaker"
import {SerialPlateImagesDialogue} from "./SerialPlateImagesDialogue"
import wizard from "./IconImages/wizard.png"
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined"
import {AnswerDialogue} from "./dialogues/AnswerDialogue"

interface DueQuestionnaireDialogueType {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  unit: Unit | undefined,
  reportId: number,
  answers: DueReportAnswer[],
  setFinishedUnits: Dispatch<SetStateAction<string[]>>,
  setRefresh: Dispatch<SetStateAction<boolean>>,
  dueQs: DueQuestion[],
}
export const DueQuestionnaireDialogue: React.FC<DueQuestionnaireDialogueType> = ({
  open,
  setOpen,
  unit,
  reportId,
  answers,
  setFinishedUnits,
  setRefresh,
  dueQs,
}): ReactElement => {

  const classes = dashboardStyles()
  const userId = useSelector((state: IAppState) => selectCurrentUserId(state))
  const [update, setUpdate] = useState<boolean>(false)
  const [openSerialImageDialogue, setOpenSerialImageDialogue] = useState<boolean>(false)
  const [unitItems, setUnitItems] = useState<ItemDetails[]>([])
  const [answerQuestion, setAnswerQuestion] = useState<DueQuestion | undefined>(undefined)
  const [openAnswer, setOpenAnswer] = useState<boolean>(false)

  const sendDueAnswer = (leaveOpen: boolean) => {
    const sendThisAnswer = async() => {
      await sendThisDueAnswer(answers)
      if (unit?.name) {
        setFinishedUnits((current) => {
          const newCurrent = [...current]
          newCurrent.push(unit?.name)
          return newCurrent
        })
      }
      setRefresh((current) => !current)
      setOpen(leaveOpen)
    }
    sendThisAnswer()
  }

  const getDueImage = (questionId: number, unitId: number): ReactNode => {
    let image = <></>
    answers.forEach((answer) => {
      if (answer.dueQuestionId === questionId && answer.unitId === unitId) {
        image = <Grid item key={`camera - ${answer.id} - ${unitId} - ${answer.dueQuestionId}`} xs={12}><DueImageTaker unitId={unitId} dueImages={answer.dueImages} setRefresh={sendDueAnswer}/></Grid>
      }
    })
    return image
  }

  const getAnswerImages = useCallback((id: number, unitId: number): ReactNode[] => {
    const images: ReactNode[] = []
    answers.forEach((answer) => {
      if(answer.dueQuestionId === id && unitId === answer.unitId) {
        if (answer?.dueImages) {
          answer.dueImages.forEach((image) => {
            images.push(
              <Grid item xs={12} style={{height:"150px", width: "150px"}}>
                <img src={image?.link} alt='serial plate' style={{ width: "100%"}} />
              </Grid>
            )
          })
        }
      }
    })
    return images
  },[answers])

  const answerThisQuestion = (question: DueQuestion) => {
    setAnswerQuestion(question)
    setOpenAnswer(true)
  }

  const defaultAllAnswers = () => {
    dueQs.forEach((question) => {
      if (question.answers.length > 0) {
        if (!answers.find((answer) => answer.dueQuestionId === question.question.id && answer.unitId === unit?.id)) {
          if (question.question.questionType === "R" && unit?.id) {
            answers.push({
              dueQuestionId: question.question.id,
              dueQuestionOptionId: question.answers[0].id,
              textAnswer: "2",
              unitId: unit.id,
              userId: userId,
              dueReportId: reportId,
              dueImages: []
            })
          } else if (question.question.questionType === "M" && unit?.id){
            const option = question.answers.find((answer) => answer.questionOption === "Yes")
            if (option) {
              answers.push({
                dueQuestionId: question.question.id,
                dueQuestionOptionId: option.id,
                textAnswer: "Yes",
                unitId: unit.id,
                userId: userId,
                dueReportId: reportId,
                dueImages: []
              })
            }

          }
        }
      }
    })
    setUpdate((current) => !current)
  }

  const radialList = useMemo(() => {
    const getNameWithFinished = (questionId: number): ReactNode => {
      let name = <></>
      let found = false
      let thisAnswer: string | undefined = ""
      let thisComment: string | undefined = ""
      answers.forEach((answer) => {
        if (answer.dueQuestionId === questionId && unit?.id && unit.id === answer.unitId) {
          found = true
          thisAnswer = answer.textAnswer
          thisComment = answer.comment
        }
      })
      dueQs.forEach((question) => {
        if (question.question.id === questionId && !found) {
          name = <Grid item key={`no-answer-question${question.question.id}`} xs={12}><Typography variant={"h2"} >{question.question.question}</Typography> unanswered </Grid>
        } else if (question.question.id === questionId) {
          name = <Grid item key={`answer-question${question.question.id}`} xs={12}><Typography variant={"h2"} >{question.question.question}</Typography>
            Current Answer: {thisAnswer} <br/>
            Comments: {thisComment}
          </Grid>
        }
      })
      return name
    }
    const radials: ReactNode[] = []
    if (dueQs && dueQs.length) {
      dueQs.forEach((question, outerIndex) => {
        const answers: ReactNode[] = []
        const nodes: ReactNode[] = []
        question.answers.forEach((answer) => {
          nodes.push(<Grid item> {answer.questionOption !== "No" ? <Button variant="outlined" onClick={() => answerThisQuestion(question)}>Answer Question</Button> : <></>}
            {unit?.id ? getAnswerImages(answer.dueQuestionId, unit.id) : <></>} </Grid>)
        })
        radials.push(
          <Grid container key={`outerContainer-${outerIndex}`} style={{ marginBottom: 32 }}>
            <Grid item xs={12}>{getNameWithFinished(question.question.id)}</Grid>
            <Grid container item xs={12}>
              { answers.length ?
                <RadioGroup name={question.question.question}>
                  {answers}
                </RadioGroup> :
                nodes
              }
            </Grid>
          </Grid>)
      }) } else { radials.push(<Grid container key={"emptyContainer"} style={{ marginBottom: 32 }}></Grid>)}
    return radials
  },[dueQs, classes.fullWidthField, update, answers, getDueImage, unit?.id, getAnswerImages]) // do not remove update

  const getSerialImages = (unitId: number | undefined) => {
    const getTheseSerialImages = async() => {
      if (unitId) {
        const items = await getSerialItemPics(unitId)
        setUnitItems(items)
      }
    }
    getTheseSerialImages()
  }

  useEffect(() => {
    if (unitItems && unitItems.length) {
      setOpenSerialImageDialogue(true)
    }
  }, [unitItems])

  return <Grid item container xs={12}>
    <Grid item container style={{maxHeight: 200}}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => {
        }}
        aria-labelledby='max-width-dialog-title'
      >
        <DialogTitle id="max-width-dialog-title">Due Diligence Questionnaire: Unit - {unit?.name}</DialogTitle>
        <DialogContent>
          <Grid container style={{ marginBottom: 64 }}>
            <Grid item xs={12}> <Typography variant={"h3"}>Key: 1=NEW 2=Good 3=Fair 4=Poor / Replace 5= Replace / Resurface / Missing</Typography></Grid>
            <Grid item xs={12}> <Typography variant={"h3"}>Yes: Item Present and Working</Typography></Grid>
            <Grid item xs={12}> <Typography variant={"h3"}>No: Item Not Present or Not Working</Typography></Grid>
            <Grid item style={{float: "right", position: "absolute", right: "49px"}} xs={12}>
              <IconButton onClick={() => getSerialImages(unit?.id)}> <Icon><img src={wizard} alt={"can't find"} height={25} width={35}/></Icon><CameraAltOutlinedIcon/></IconButton>
            </Grid>
            {radialList}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {
            defaultAllAnswers()
          }}>Set unanswered to default</Button>
          <Button variant="outlined" onClick={() => {
            sendDueAnswer(false)
          }}>finished</Button>
          <Button variant="outlined" onClick={() => {
            setRefresh((current) => !current)
            setOpen(false)
          }}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {unit?.id ? <SerialPlateImagesDialogue open={openSerialImageDialogue} setOpen={setOpenSerialImageDialogue} items={unitItems} setItems={setUnitItems} unitId={unit.id} setUpdateImages={getSerialImages}/>: <div></div>}
      {answerQuestion && unit ? <AnswerDialogue open={openAnswer} setOpen={setOpenAnswer} question={answerQuestion} dueQs={dueQs} answers={answers} userId={userId} reportId={reportId} unit={unit} update={update} setUpdate={setUpdate} setFinishedUnits={setFinishedUnits} setRefresh={setRefresh}/> : <></>}
    </Grid>
  </Grid>

}
